var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pre-Column",style:({
    'background-image': `url(${_vm.checkBackgroundImg})`,
    'background-color': `${_vm.page && _vm.page.colorBackground}`,
    'background-repeat': _vm.returnRepeat(`${_vm.page && _vm.page.repeatBackground}`),
    'background-attachment': _vm.returnFixed(`${_vm.page && _vm.page.fixedBackground}`),
    'background-position': _vm.returnPlace(`${_vm.page && _vm.page.placeBackground}`),
    display: _vm.returnCheckView(),
  })},[_c('v-style',[_vm._v(" "+_vm._s(_vm.page && _vm.page.css)+" ")]),_c('div',{staticClass:"header-content"},[_c('div',{staticClass:"container",staticStyle:{"min-height":"90vh"}},[_c('div',{staticClass:"header-pre row"},[(_vm.optionColumn && _vm.checkHeaderImg)?_c('img',{attrs:{"src":_vm.checkHeaderImg}}):_vm._e()]),_c('div',{staticClass:"content-pre ct-home row",class:_vm.page &&
          (_vm.page.type_header == _vm.type_header_constant['frame'] ||
            _vm.page.type_header == _vm.type_header_constant['menu_frame'])
            ? 'content-frame'
            : '',style:({
          'background-image': `url(${_vm.checkArticleImg})`,
          'background-color': `${_vm.page && _vm.page.colorArticle}`,
          'background-repeat': _vm.returnRepeat(`${_vm.page && _vm.page.repeatArticle}`),
          'background-attachment': _vm.returnFixed(
            `${_vm.page && _vm.page.fixedArticle}`
          ),
          'background-position': _vm.returnPlace(`${_vm.page && _vm.page.placeArticle}`),
          '-webkit-box-shadow': _vm.returnShadow(`${_vm.page && _vm.page.colorShadow}`),
        })},[_c('div',{staticClass:"author-content mt-3",class:_vm.page && _vm.page.type_header != _vm.type_header_constant['white_page']
              ? 'margin-content w-100'
              : 'w-100'},[_c('div',{staticClass:"card-content pt-3 row accordion-page"},_vm._l((_vm.page && _vm.page.description),function(value,key){return _c('div',{key:key,staticClass:"ql-editor previewspage",staticStyle:{"width":"100%"}},[_c('b-card-body',{staticClass:"px-0"},_vm._l((value.description.blocks),function(itemBody,indexBody){return _c('div',{key:indexBody,staticClass:"w-100",class:itemBody.type === 'toggle' ? 'mt-2' : ''},[(itemBody.type === 'paragraph')?_c('div',{staticClass:"item-data w-100",class:itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : _vm.checkIndex(itemBody, value.description.blocks)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3',attrs:{"id":itemBody.id,"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('div',{domProps:{"innerHTML":_vm._s(itemBody.data.text)}})]):_vm._e(),(itemBody.type === 'tableOfContents')?_c('div',{staticClass:"item-data w-100",attrs:{"id":itemBody.id}},[_c('h3',[_vm._v("目次")]),_c('ul',{staticClass:"list-unstyled pl-4 listTableOfContent"},_vm._l((itemBody.data.items),function(itemTOC,index){return _c('li',{key:index,staticClass:"pl-3"},[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                            el: '#' + itemTOC.href,
                            offset: -20,
                          }),expression:"{\n                            el: '#' + itemTOC.href,\n                            offset: -20,\n                          }"}],staticStyle:{"cursor":"pointer"},domProps:{"innerHTML":_vm._s(itemTOC.text)}})])}),0)]):_vm._e(),(itemBody.type === 'header')?_c('div',{staticClass:"item-data w-100",attrs:{"id":itemBody.id,"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[(itemBody.data.level === 1)?_c('h1',{staticClass:"mb-0",class:itemBody.tunes && itemBody.idChild
                          ? itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'toggle-block__item text-center'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'toggle-block__item text-right'
                            : _vm.checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'text-center my-3'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'text-right my-3'
                          : ' text-left my-3',domProps:{"innerHTML":_vm._s(itemBody.data.text)}}):_vm._e(),(itemBody.data.level === 2)?_c('h2',{staticClass:"mb-0",class:itemBody.tunes && itemBody.idChild
                          ? itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'toggle-block__item text-center'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'toggle-block__item text-right'
                            : _vm.checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'text-center my-3'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'text-right my-3'
                          : ' text-left my-3',domProps:{"innerHTML":_vm._s(itemBody.data.text)}}):_vm._e(),(itemBody.data.level === 3)?_c('h3',{staticClass:"mb-0",class:itemBody.tunes && itemBody.idChild
                          ? itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'toggle-block__item text-center'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'toggle-block__item text-right'
                            : _vm.checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'text-center my-3'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'text-right my-3'
                          : ' text-left my-3',domProps:{"innerHTML":_vm._s(itemBody.data.text)}}):_vm._e(),(itemBody.data.level === 4)?_c('h4',{staticClass:"mb-0",class:itemBody.tunes && itemBody.idChild
                          ? itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'toggle-block__item text-center'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'toggle-block__item text-right'
                            : _vm.checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'text-center my-3'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'text-right my-3'
                          : ' text-left my-3',domProps:{"innerHTML":_vm._s(itemBody.data.text)}}):_vm._e(),(itemBody.data.level === 5)?_c('h5',{staticClass:"mb-0",class:itemBody.tunes && itemBody.idChild
                          ? itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'toggle-block__item text-center'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'toggle-block__item text-right'
                            : _vm.checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'text-center my-3'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'text-right my-3'
                          : ' text-left my-3',domProps:{"innerHTML":_vm._s(itemBody.data.text)}}):_vm._e(),(itemBody.data.level === 6)?_c('h6',{staticClass:"mb-0",class:itemBody.tunes && itemBody.idChild
                          ? itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'toggle-block__item text-center'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'toggle-block__item text-right'
                            : _vm.checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'text-center my-3'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'text-right my-3'
                          : ' text-left my-3',domProps:{"innerHTML":_vm._s(itemBody.data.text)}}):_vm._e()]):_vm._e(),(itemBody.type === 'list')?_c('div',{staticClass:"item-data w-100",class:itemBody.idChild
                        ? _vm.checkIndex(itemBody, value.description.blocks)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : 'my-3',attrs:{"id":itemBody.id,"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('ul',{staticClass:"w-100",staticStyle:{"list-style-type":"decimal"}},_vm._l((itemBody.data.items),function(itemList,indexList){return _c('li',{key:indexList,staticClass:"mb-2",domProps:{"innerHTML":_vm._s(itemList)}})}),0)]):_vm._e(),(itemBody.type === 'toggle')?_c('div',{staticClass:"item-data w-100",attrs:{"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('div',{staticClass:"w-100 d-flex align-items-center toggle-block__selector",class:itemBody.data.status === 'open'
                          ? 'toggle-open'
                          : 'toggle-close',attrs:{"id":itemBody.id}},[_c('span',{staticClass:"icon-toggle-panel mr-2",on:{"click":function($event){return _vm.toggleBlock(itemBody)}}},[_c('b-icon',{attrs:{"icon":itemBody.data.status === 'open'
                              ? 'x-lg'
                              : 'plus-lg'}})],1),_vm._v(" "+_vm._s(itemBody.data.text)+" ")])]):_vm._e(),(itemBody.type === 'code')?_c('div',{staticClass:"item-data w-100",class:itemBody.idChild
                        ? _vm.checkIndex(itemBody, value.description.blocks)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : 'my-3',attrs:{"id":itemBody.id,"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('div',{domProps:{"innerHTML":_vm._s(itemBody.data.code)}})]):_vm._e(),(itemBody.type === 'raw')?_c('div',{staticClass:"item-data w-100 my-3",class:itemBody.idChild
                        ? _vm.checkIndex(itemBody, value.description.blocks)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : '',attrs:{"id":itemBody.id,"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('div',{domProps:{"innerHTML":_vm._s(itemBody.data.html)}})]):_vm._e(),(itemBody.type === 'table')?_c('div',{staticClass:"item-data w-100",class:itemBody.idChild
                        ? _vm.checkIndex(itemBody, value.description.blocks)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : 'my-3',attrs:{"id":itemBody.id,"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('table',{staticClass:"table table-bordered"},[_c('thead',[_c('tr',_vm._l((itemBody.data
                              .content[0]),function(itemTblTh,indexTblTh){return _c('th',{key:indexTblTh,staticClass:"border-bottom-0"},[_c('span',{staticClass:"w-100",domProps:{"innerHTML":_vm._s(itemTblTh)}})])}),0)]),_c('tbody',_vm._l((itemBody.data
                            .contentTbody),function(itemTblTr,indexTblTr){return _c('tr',{key:indexTblTr},_vm._l((itemTblTr),function(itemTblTd,indexTblTd){return _c('td',{key:indexTblTd},[_c('span',{staticClass:"w-100",domProps:{"innerHTML":_vm._s(itemTblTd)}})])}),0)}),0)])]):_vm._e(),(itemBody.type === 'checklist')?_c('div',{staticClass:"item-data w-100",class:itemBody.idChild
                        ? _vm.checkIndex(itemBody, value.description.blocks)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : 'my-3',attrs:{"id":itemBody.id,"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},_vm._l((itemBody.data
                        .items),function(itemCheckbox,indexCheckbox){return _c('div',{key:indexCheckbox,staticClass:"custom-control custom-checkbox"},[_c('input',{staticClass:"custom-control-input",attrs:{"id":'checkbox-' + itemBody.id + indexCheckbox,"type":"checkbox","name":'checkbox-' + itemBody.id + indexCheckbox},domProps:{"checked":itemCheckbox.checked}}),_c('label',{staticClass:"custom-control-label ml-1",attrs:{"for":'checkbox-' + itemBody.id + indexCheckbox}},[_c('span',{domProps:{"innerHTML":_vm._s(itemCheckbox.text)}})])])}),0):_vm._e(),(itemBody.type === 'warning')?_c('div',{staticClass:"item-data w-100",class:itemBody.idChild
                        ? _vm.checkIndex(itemBody, value.description.blocks)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : 'my-3',attrs:{"id":itemBody.id,"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('div',{staticClass:"w-100 bg-warning p-3 text-white"},[_c('div',{staticClass:"w-100 font-weight-bold"},[_vm._v(" "+_vm._s(itemBody.data.title)+" ")]),_c('hr'),_c('div',{staticClass:"w-100"},[_vm._v(" "+_vm._s(itemBody.data.message)+" ")])])]):_vm._e(),(itemBody.type === 'quote')?_c('div',{staticClass:"item-data w-100",class:itemBody.idChild
                        ? _vm.checkIndex(itemBody, value.description.blocks)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : 'my-3',attrs:{"id":itemBody.id,"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('blockquote',{staticClass:"otro-blockquote",class:itemBody.data.alignment === 'left'
                          ? 'text-left'
                          : 'text-right'},[_c('span',[_vm._v(" "+_vm._s(itemBody.data.caption)+" ")]),_c('span',{domProps:{"innerHTML":_vm._s(itemBody.data.text)}})])]):_vm._e(),(itemBody.type === 'headerStyle')?_c('div',{staticClass:"item-data w-100",class:itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : _vm.checkIndex(itemBody, value.description.blocks)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3',attrs:{"id":itemBody.id,"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('div',{staticClass:"titleStyle",class:itemBody.data.class},[_c('h1',{staticClass:"dataInput"},[_vm._v(" "+_vm._s(itemBody.data.text)+" ")])])]):_vm._e(),(itemBody.type === 'boxStylle')?_c('div',{staticClass:"item-data w-100",class:itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : _vm.checkIndex(itemBody, value.description.blocks)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3',attrs:{"id":itemBody.id,"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('div',{staticClass:"boxStyle",class:itemBody.data.class},[(itemBody.data.title)?_c('span',{staticClass:"box-title px-2 border-0"},[_vm._v(_vm._s(itemBody.data.title))]):_vm._e(),_c('div',{staticClass:"border-0 shadow-none bg-transparent h-auto form-control p-2 dataInput"},[_vm._v(" "+_vm._s(itemBody.data.text)+" ")])])]):_vm._e(),(itemBody.type === 'delimiter')?_c('div',{staticClass:"item-data w-100",class:itemBody.idChild
                        ? _vm.checkIndex(itemBody, value.description.blocks)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : 'my-3',attrs:{"id":itemBody.id,"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('div',{staticClass:"w-100 text-center ce-delimiter"})]):_vm._e(),(itemBody.type === 'image')?_c('div',{staticClass:"item-data w-100",class:itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : _vm.checkIndex(itemBody, value.description.blocks)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3',attrs:{"id":itemBody.id,"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[(_vm.replaceText(itemBody.data.linkImage))?_c('a',{attrs:{"href":itemBody.data.linkImage}},[_c('img',{attrs:{"src":itemBody.data.file.url,"alt":"","width":"auto"}})]):_c('img',{attrs:{"src":itemBody.data.file.url,"alt":"","width":"auto"}}),_c('h4',{staticClass:"mt-2"},[_vm._v(_vm._s(itemBody.data.caption))])]):_vm._e(),(itemBody.type === 'attachesPDF')?_c('div',{staticClass:"item-data w-100",class:itemBody.idChild
                        ? _vm.checkIndex(itemBody, value.description.blocks)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : 'my-3',attrs:{"id":itemBody.id,"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('b-button',{staticClass:"btn-preview-fixed mr-0",attrs:{"variant":"primary","name":"btn-view-PDF"},on:{"click":function($event){$event.preventDefault();return _vm.viewFilePDF(itemBody.data.file, itemBody.id)}}},[_vm._v(" "+_vm._s(itemBody.data.title)+" ")])],1):_vm._e(),(
                      itemBody.type === 'embed' &&
                      itemBody.data.service === 'youtube'
                    )?_c('div',{staticClass:"item-data w-100",class:itemBody.idChild
                        ? _vm.checkIndex(itemBody, value.description.blocks)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : 'my-3',attrs:{"id":itemBody.id,"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('YoutubePlayer',{attrs:{"idElemYoutube":itemBody.id,"src":itemBody.data.source,"heightPlayer":itemBody.data.height,"widthPlayer":itemBody.data.width,"dataVideo":itemBody.data}})],1):_vm._e(),(
                      itemBody.type === 'embed' &&
                      itemBody.data.service === 'vimeo'
                    )?_c('div',{staticClass:"item-data w-100",class:itemBody.idChild
                        ? _vm.checkIndex(itemBody, value.description.blocks)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : 'my-3',attrs:{"id":itemBody.id,"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('VimeoPlayer',{attrs:{"idElemVimeo":itemBody.id,"src":itemBody.data.embed,"heightPlayer":itemBody.data.height,"widthPlayer":itemBody.data.width,"dataVideo":itemBody.data}})],1):_vm._e(),(itemBody.type === 'anyButton')?_c('div',{staticClass:"item-data w-100",class:itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : _vm.checkIndex(itemBody, value.description.blocks)
                          ? 'toggle-block__item text-left toggle-item-last'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3',attrs:{"id":itemBody.id,"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[(itemBody.data.type === null)?_c('a',{staticClass:"anyButton",class:itemBody.data.style,on:{"click":function($event){return _vm.downloadFile($event, itemBody.data)}}},[_vm._v(" "+_vm._s(itemBody.data.text)+" ")]):_vm._e(),(
                        itemBody.data.type !== null &&
                        (itemBody.data.style === 'roundButton' ||
                          itemBody.data.style === 'socialGlossySmooth' ||
                          itemBody.data.style === 'socialSquare')
                      )?_c('div',{staticClass:"w-100",class:itemBody.tunes && itemBody.idChild
                          ? itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'toggle-block__item text-center'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'toggle-block__item text-right'
                            : _vm.checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'text-center my-3'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'text-right my-3'
                          : ' text-left my-3'},[(itemBody.data.twitter !== null)?_c('a',{staticClass:"btn-social-circle btn-social-circle--twitter",attrs:{"href":itemBody.data.twitter}},[_c('i',{staticClass:"fab fa-twitter"})]):_vm._e(),(itemBody.data.facebook !== null)?_c('a',{staticClass:"btn-social-circle btn-social-circle--facebook",attrs:{"href":itemBody.data.facebook}},[_c('i',{staticClass:"fab fa-facebook-f"})]):_vm._e(),(itemBody.data.pocket !== null)?_c('a',{staticClass:"btn-social-circle btn-social-circle--pocket",attrs:{"href":itemBody.data.pocket}},[_c('i',{staticClass:"fab fa-get-pocket"})]):_vm._e(),(itemBody.data.feedly !== null)?_c('a',{staticClass:"btn-social-circle btn-social-circle--feedly",attrs:{"href":itemBody.data.feedly}},[_c('i',{staticClass:"fas fa-rss"})]):_vm._e()]):_vm._e(),(
                        itemBody.data.type !== null &&
                        (itemBody.data.style === 'socialGiza' ||
                          itemBody.data.style === 'socialSmartPhone')
                      )?_c('div',{staticClass:"w-100",class:itemBody.tunes && itemBody.idChild
                          ? itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'toggle-block__item text-center'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'toggle-block__item text-right'
                            : _vm.checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'text-center my-3'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'text-right my-3'
                          : ' text-left my-3'},[_c('div',{class:itemBody.data.style === 'socialGiza'
                            ? 'btn-social-giza'
                            : 'btn-social-phone'},[(itemBody.data.twitter !== null)?_c('a',{class:itemBody.data.style === 'socialGiza'
                              ? 'btn-social-giza-twitter'
                              : 'btn-social-phone-twitter ml-0 mr-3',attrs:{"href":"1"}},[_c('span',{staticClass:"fa-stack"},[_c('i',{staticClass:"fas fa-stack-2x",class:itemBody.data.style === 'socialGiza'
                                  ? 'fa-certificate'
                                  : 'fa-tablet-alt'}),_c('i',{staticClass:"fab fa-twitter fa-stack-1x"})])]):_vm._e(),(itemBody.data.facebook !== null)?_c('a',{class:itemBody.data.style === 'socialGiza'
                              ? 'btn-social-giza-facebook'
                              : 'btn-social-phone-facebook ml-0 mr-3',attrs:{"href":"3535"}},[_c('span',{staticClass:"fa-stack"},[_c('i',{staticClass:"fas fa-stack-2x",class:itemBody.data.style === 'socialGiza'
                                  ? 'fa-certificate'
                                  : 'fa-tablet-alt'}),_c('i',{staticClass:"fab fa-facebook-f fa-stack-1x"})])]):_vm._e(),(itemBody.data.pocket !== null)?_c('a',{class:itemBody.data.style === 'socialGiza'
                              ? 'btn-social-giza-pocket'
                              : 'btn-social-phone-pocket ml-0 mr-3',attrs:{"href":"5467"}},[_c('span',{staticClass:"fa-stack"},[_c('i',{staticClass:"fas fa-stack-2x",class:itemBody.data.style === 'socialGiza'
                                  ? 'fa-certificate'
                                  : 'fa-tablet-alt'}),_c('i',{staticClass:"fab fa-get-pocket fa-stack-1x"})])]):_vm._e(),(itemBody.data.feedly !== null)?_c('a',{class:itemBody.data.style === 'socialGiza'
                              ? 'btn-social-giza-feedly'
                              : 'btn-social-phone-feedly ml-0 mr-3',attrs:{"href":"1"}},[_c('span',{staticClass:"fa-stack"},[_c('i',{staticClass:"fas fa-stack-2x",class:itemBody.data.style === 'socialGiza'
                                  ? 'fa-certificate'
                                  : 'fa-tablet-alt'}),_c('i',{staticClass:"fas fa-rss fa-stack-1x"})])]):_vm._e()])]):_vm._e(),(
                        itemBody.data.type !== null &&
                        (itemBody.data.style === 'socialFlat' ||
                          itemBody.data.style === 'socialIsometric')
                      )?_c('div',{staticClass:"w-100",class:itemBody.tunes && itemBody.idChild
                          ? itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'toggle-block__item text-center'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'toggle-block__item text-right'
                            : _vm.checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'text-center my-3'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'text-right my-3'
                          : ' text-left my-3'},[_c('div',[_c('a',{class:itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat'
                              : 'btn-social-isometric',attrs:{"href":"1"}},[_c('span',{class:itemBody.data.style === 'socialFlat'
                                ? 'btn-social-flat-icon btn-social-isometric-icon--twitter'
                                : 'btn-social-isometric-icon btn-social-isometric-icon--twitter'},[_c('i',{staticClass:"fab fa-twitter"})]),_c('span',{class:itemBody.data.style === 'socialFlat'
                                ? 'btn-social-flat-text'
                                : 'btn-social-isometric-text'},[_vm._v("TWEET")])])]),_c('div',[_c('a',{class:itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat'
                              : 'btn-social-isometric',attrs:{"href":"1"}},[_c('span',{class:itemBody.data.style === 'socialFlat'
                                ? 'btn-social-flat-icon btn-social-isometric-icon--facebook'
                                : 'btn-social-isometric-icon btn-social-isometric-icon--facebook'},[_c('i',{staticClass:"fab fa-facebook"})]),_c('span',{class:itemBody.data.style === 'socialFlat'
                                ? 'btn-social-flat-text'
                                : 'btn-social-isometric-text'},[_vm._v("TWEET")])])]),_c('div',[_c('a',{class:itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat'
                              : 'btn-social-isometric',attrs:{"href":"1"}},[_c('span',{class:itemBody.data.style === 'socialFlat'
                                ? 'btn-social-flat-icon btn-social-isometric-icon--pocket'
                                : 'btn-social-isometric-icon btn-social-isometric-icon--pocket'},[_c('i',{staticClass:"fab fa-get-pocket"})]),_c('span',{class:itemBody.data.style === 'socialFlat'
                                ? 'btn-social-flat-text'
                                : 'btn-social-isometric-text'},[_vm._v("TWEET")])])]),_c('div',[_c('a',{class:itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat'
                              : 'btn-social-isometric',attrs:{"href":"1"}},[_c('span',{class:itemBody.data.style === 'socialFlat'
                                ? 'btn-social-flat-icon btn-social-isometric-icon--feedly'
                                : 'btn-social-isometric-icon btn-social-isometric-icon--feedly'},[_c('i',{staticClass:"fas fa-rss"})]),_c('span',{class:itemBody.data.style === 'socialFlat'
                                ? 'btn-social-flat-text'
                                : 'btn-social-isometric-text'},[_vm._v("TWEET")])])])]):_vm._e()]):_vm._e(),(itemBody.type === 'attachesFile')?_c('div',{staticClass:"item-data w-100 my-3",attrs:{"id":itemBody.id}},[_c('div',{staticClass:"w-100 d-flex box-file-upload"},[_c('div',{staticClass:"iconFile",style:({ color: itemBody.data.file.color }),attrs:{"data-extension":itemBody.data.file.extension}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"32","height":"40"}},[_c('path',{attrs:{"d":"M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z"}})])]),_c('div',{staticClass:"fileName"},[_c('div',{staticClass:"w-100"},[_vm._v(_vm._s(itemBody.data.file.name))]),_c('div',{staticClass:"text-muted w-100",domProps:{"innerHTML":_vm._s(_vm.convertSize(itemBody.data.file.size))}})]),_c('div',{staticClass:"iconDowload ml-auto"},[_c('a',{staticClass:"iconDowload",attrs:{"download":itemBody.data.file.url,"rel":"nofollow noindex noreferrer"},on:{"click":function($event){return _vm.downloadFileUpload($event, itemBody.data.file)}}},[_c('i',{staticClass:"fas fa-cloud-download-alt"})])])])]):_vm._e()])}),0)],1)}),0),_c('div',{staticClass:"card-content pt-3 row align-items-center justify-content-center"},[_c('button',{staticClass:"float-right button-back-dashboard back-btn",on:{"click":function($event){return _vm.returnPage()}}},[_vm._v(" 戻る ")])])])]),_c('b-modal',{attrs:{"id":"modal-detail-pdf","hide-footer":"","modal-class":"modal-fullscreen"},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('ViewPdf',{attrs:{"src":_vm.filePDFView && _vm.filePDFView.url,"idComponent":_vm.filePDFView && _vm.filePDFView.idComponent,"dataInfo":_vm.filePDFView}})],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }